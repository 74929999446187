import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import ProductWithImage from "../../../components/ProductWithImage";
import { Message } from "../../../lib/getMessage";
import WebsiteScrenshoot from "../../images/website-app-service-screenshot.png";
import WebsiteScreenshot2 from "../../images/website-app-service-screenshot@2x.png";
import WebsiteScreenshot3 from "../../images/website-app-service-screenshot@3x.png";

import LanguageSelectorScreen from "../../images/language-selector-screen1.png";
import LanguageSelectorScreen2 from "../../images/language-selector-screen1@2x.png";
import LanguageSelectorScreen3 from "../../images/language-selector-screen1@3x.png";

import Arrow from "../../images/website-feat-arrow.png";
import Arrow1 from "../../images/website-feat-arrow@2x.png";
import Arrow2 from "../../images/website-feat-arrow@3x.png";

import SelectedLanguageScreen from "../../images/selected-language-screen.png";
import SelectedLanguageScreen1 from "../../images/selected-language-screen@2x.png";
import SelectedLanguageScreen2 from "../../images/selected-language-screen@3x.png";

import ArrowForMobile from "../../images/vertical-website-feat-arrow.png";
import ArrowForMobile1 from "../../images/vertical-website-feat-arrow@2x.png";
import ArrowForMobile2 from "../../images/vertical-website-feat-arrow@3x.png";

import LanguageSelectorMobileScreen from "../../images/language-selector-mobile-screen.png";
import LanguageSelectorMobileScreen1 from "../../images/language-selector-mobile-screen@2x.png";
import LanguageSelectorMobileScreen2 from "../../images/language-selector-mobile-screen@3x.png";

import SelectedLanguageMobileScreen from "../../images/selected-language-mobile.png";
import SelectedLanguageMobileScreen1 from "../../images/selected-language-mobile@2x.png";
import SelectedLanguageMobileScreen2 from "../../images/selected-language-mobile@3x.png";

import ListView from "../../../components/ListView";

import "./style.css";
const ConnectingImage = (props) => {
  const { image, image2, image3, description, className, name } = props;
  const twoX = image.xx ? `${image.xx} 2x` : "";
  const threeX = image.xxx ? `${image.xxx} 3x` : "";

  return (
    <div className={`connect-img ${className}`} name={`${name}`}>
      <div className="image-content-main">
        <div className="image-main">
          <img
            src={image.normal}
            srcSet={`${twoX} 2x, ${threeX}`}
            className="connected1-img"
            alt="media"
            loading="lazy"
          />
          <img
            src={image2.normal}
            srcSet={`${image2.xx} 2x, ${image2.xxx} 3x`}
            className="connecting-img"
            alt="media"
            loading="lazy"
          />
          <img
            src={image3.normal}
            srcSet={`${image3.xx} 2x, ${image3.xxx} 3x`}
            className="connected2-img"
            alt="media"
            loading="lazy"
          />
        </div>
        <div className="content-main">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
const WebsiteServicepDesc = (
  <ListView
    lists={[
      <Message dictKey="products.websiteandmobileapps.websiteService.item1" />,
      <Message dictKey="products.websiteandmobileapps.websiteService.item2" />,
    ]}
  />
);

const WebsiteAndMobileApp = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-web-mobile">
          <HeroBanner
            title={<Message dictKey="products.websiteandmobileapps.title" />}
            className={`website-mobile-banner ${
              isDirectionRTL && "website-mobile-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />
          <section className="website-service-wrp">
            <ProductWithImage
              name="website-service"
              className="website-service"
              description={[{ type: "p", val: WebsiteServicepDesc, id: 1 }]}
              image={{
                normal: WebsiteScrenshoot,
                xx: WebsiteScreenshot2,
                xxx: WebsiteScreenshot3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
          <section className="language-service-wrp">
            <ConnectingImage
              name="language-service"
              className="language-service"
              image={{
                normal: LanguageSelectorScreen,
                xx: LanguageSelectorScreen2,
                xxx: LanguageSelectorScreen3,
              }}
              image2={{
                normal: Arrow,
                xx: Arrow1,
                xxx: Arrow2,
              }}
              image3={{
                normal: SelectedLanguageScreen,
                xx: SelectedLanguageScreen1,
                xxx: SelectedLanguageScreen2,
              }}
              description={
                <Message dictKey="products.websiteandmobileapps.languageservice.description" />
              }
            />
          </section>
          <section className="language-service-wrp-mobile">
            <ConnectingImage
              name="language-service"
              className="language-service"
              image={{
                normal: LanguageSelectorMobileScreen,
                xx: LanguageSelectorMobileScreen1,
                xxx: LanguageSelectorMobileScreen2,
              }}
              image2={{
                normal: ArrowForMobile,
                xx: ArrowForMobile1,
                xxx: ArrowForMobile2,
              }}
              image3={{
                normal: SelectedLanguageMobileScreen,
                xx: SelectedLanguageMobileScreen1,
                xxx: SelectedLanguageMobileScreen2,
              }}
              description={
                <Message dictKey="products.websiteandmobileapps.websiteService.item2" />
              }
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(WebsiteAndMobileApp);
